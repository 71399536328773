import { React, useState } from "react";
import "./Kard.css";
function Kard({ kurslar, setKurs_id }) {
  const [kardClass, setkardClass] = useState(1);

  const aktivKardClass = (i) => {
    setkardClass(i);
  };
  const kardlar = kurslar
    .filter((kurs) => kurs.turi === 2)
    .map((kurs) => {
      return (
        <div
          onClick={() => {
            setKurs_id(kurs.id - 1);
            aktivKardClass(kurs.id);
          }}
          className={
            kardClass === kurs.id ? "kard-icon kardAktiv" : "kard-icon"
          }
          key={kurs.id}
        >
          <img src={kurs.rasm} alt={kurs.nomi} />
          <span>{kurs.nomi}</span>
        </div>
      );
    });
  return <div className="kard-quti">{kardlar}</div>;
}

export default Kard;
