import React from "react";
import Sarlavha from "../Masala/Sarlavha";
import Kard from "./Kard";

function Bosh({ kurslar }) {
  return (
    <div className="bosh-quti">
      <Sarlavha chap="Bizning kurslar" ong="Algoritm va mantiq" />
      <Kard kurslar={kurslar} turi={1} />
      <Sarlavha ong="Web dasturlash" />
      <Kard kurslar={kurslar} turi={2} />
      <Sarlavha ong="Mobil dasturlash" />
      <Kard kurslar={kurslar} turi={3} />
      {/* <Sarlavha ong="Robotatexnika" />
      <Kard kurslar={kurslar} turi={4} /> */}
    </div>
  );
}

export default Bosh;
