import React from "react";
import "./Qidiruv.css";
import { FaSearch } from "react-icons/fa";

function Qidiruv() {
  return (
    <div className="qidiruv-quti">
      <div className="qidiruv-sarlavha">
        <p>Kurslarni tanlang</p>
      </div>
      <div className="qidiruv-input">
        <form id="qidiruv-form">
          <input type="text" placeholder="Qidiruv" />
          <button>
            <FaSearch />
          </button>
        </form>
      </div>
    </div>
  );
}
export default Qidiruv;
