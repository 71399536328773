import React from "react";
import Sarlavha from "../Masala/Sarlavha";
import Taxrirlovchi from "../Taxrirlovchi";
import Dars from "./Dars";
import "./Kurs.css";
import Video from "./Video";
function Kurs({ baza }) {
  return (
    <div className="kurs-quti">
      <Sarlavha chap="Nazariy ma'lumotlar" ong="Videodars" />
      <div className="kurs-dars-quti">
        <Dars />
        <Video />
      </div>
      <div className="kurs-dars-quti">
        <Taxrirlovchi
          til={baza.kurs}
          nomi="JavaScript"
          qiymat={baza.js}
          kodYozish={baza.setJs}
          Natija={baza.Natija}
          rasm={baza.rasm}
        />
        <div className="masala-korinish">
          <iframe
            className="natija"
            srcDoc={baza.baza}
            title="output"
            sandbox="allow-scripts"
            frameBorder="0"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
}

export default Kurs;
