import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaRobot,
  FaBrain,
  FaReact,
  FaBook,
  FaNewspaper,
  FaCog,
  FaDoorOpen,
  FaPython,
  FaAndroid,
} from "react-icons/fa";

function IconMenyu(icon) {
  const icons = <FaBook className="icon-menyu" />;
  return (
    <>
      <NavLink className="menyu-qiymat" to="/mantiq" activeClassName="faol">
        <FaBrain className="icon-menyu" />
        <span>Mantiq</span>
      </NavLink>
      <NavLink className="menyu-qiymat" to="/web/" activeClassName="faol">
        <FaReact className="icon-menyu" />
        <span>Web</span>
      </NavLink>
      <NavLink className="menyu-qiymat" to="/mobil" activeClassName="faol">
        <FaAndroid className="icon-menyu" />
        <span>Mobil</span>
      </NavLink>
      <NavLink className="menyu-qiymat" to="/robot" activeClassName="faol">
        <FaRobot className="icon-menyu" />
        <span>Robot</span>
      </NavLink>
      <NavLink className="menyu-qiymat" to="/kitob" activeClassName="faol">
        <FaBook className="icon-menyu" />
        <span>Kitob</span>
      </NavLink>
      <NavLink className="menyu-qiymat" to="/yangilik" activeClassName="faol">
        <FaNewspaper className="icon-menyu" />
        <span>Yangilik</span>
      </NavLink>
      <NavLink className="menyu-qiymat" to="/sozlama" activeClassName="faol">
        <FaCog className="icon-menyu" />
        <span>Sozlama</span>
      </NavLink>
      <NavLink className="menyu-qiymat" to="/chiqish" activeClassName="faol">
        <FaDoorOpen className="icon-menyu" />
        <span>Chiqish</span>
      </NavLink>
    </>
  );
}

export default IconMenyu;
