import React from "react";
import { Link } from "react-router-dom";
import Rasm from "../Rasm/logo.png";

function Logo() {
  return (
    <Link to="/" className="menyu-logo">
      <img src={Rasm} />
      <span>Suvoriy</span>
    </Link>
  );
}

export default Logo;
