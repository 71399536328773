import { React } from "react";
import "../Qiymat/Kard.css";
function Kard({ kurslar, turi }) {
  const kardlar = kurslar
    .filter((kurs) => kurs.turi === turi)
    .map((kurs) => {
      return (
        <div className="kard-icon" key={kurs.id}>
          <img src={kurs.rasm} alt={kurs.nomi} />
          <span>{kurs.nomi}</span>
        </div>
      );
    });
  return <div className="kard-quti">{kardlar}</div>;
}

export default Kard;
