import react from "react";
import "./Kurs.css";
import html from "../Rasm/html.png";
import css from "../Rasm/css.png";
import js from "../Rasm/js.png";
import bootstrap from "../Rasm/bootstrap.png";
import reactjs from "../Rasm/react.png";
import vue from "../Rasm/vue.png";
import php from "../Rasm/php.png";
import laravel from "../Rasm/laravel.png";
import python from "../Rasm/python.png";
import django from "../Rasm/django.png";
import java from "../Rasm/java.png";
import kotlin from "../Rasm/kotlin.svg";
import swift from "../Rasm/swift.png";
import flutter from "../Rasm/flutter.png";
import mantiq from "../Rasm/mantiq.png";
import mb from "../Rasm/mb.png";
import { FaCheckCircle, FaSearch, FaStar } from "react-icons/fa";

export default function Kurs() {
  return (
    <div className="foydalanuvchi-kurs-asosiy-quti">
      <div className="foydalanuvchi-kurs-quti">
        <div className="foydalanuvchi-kurs">
          <div className="sarlavha">
            Obuna kurslar
            <span>16 dona</span>
          </div>
        </div>
        <div className="foydalanuvchi-kurs-qidiruv">
          <form id="qidiruv-form">
            <input type="text" placeholder="Kurs izlash" />
            <button type="button">
              <FaSearch />
            </button>
          </form>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={html} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>HTML</div>
            <span>
              <FaCheckCircle /> 21/21
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={css} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>CSS</div>
            <span>
              <FaCheckCircle /> 44/44
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={js} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>JavaScript</div>
            <span>
              <FaCheckCircle /> 80/80
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={bootstrap} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>Bootstrap</div>
            <span>
              <FaCheckCircle /> 36/36
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={reactjs} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>React JS</div>
            <span>
              <FaCheckCircle /> 48/48
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar />
            <FaStar />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={vue} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>Vue je</div>
            <span>
              <FaCheckCircle /> 17/35
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={php} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>php MySQL</div>
            <span>
              <FaCheckCircle /> 112/112
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={laravel} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>Laravel</div>
            <span>
              <FaCheckCircle /> 74/74
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar />
            <FaStar />
            <FaStar />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={python} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>Python</div>
            <span>
              <FaCheckCircle /> 117/125
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar />
            <FaStar />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={django} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>Django</div>
            <span>
              <FaCheckCircle /> 81/88
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={java} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>Java</div>
            <span>
              <FaCheckCircle /> 114/153
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={kotlin} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>Kotlin</div>
            <span>
              <FaCheckCircle /> 108/137
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={swift} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>Swift</div>
            <span>
              <FaCheckCircle /> 116/148
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={flutter} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>Flutter</div>
            <span>
              <FaCheckCircle /> 116/148
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={mantiq} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>Algoritm va mantiq</div>
            <span>
              <FaCheckCircle /> 389/417
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar />
            <FaStar />
          </div>
        </div>
      </div>
      <div className="foydalanuvchi-kurs-qism">
        <div className="foydalanuvchi-kurs-rasm">
          <img src={mb} alt="" />
        </div>
        <div className="foydalanuvchi-kurs-bolim">
          <div className="foydalanuvchi-kurs-nomi">
            <div>Ma'lumotlar bazasi</div>
            <span>
              <FaCheckCircle /> 124/128
            </span>
          </div>
          <div className="foydalanuvchi-kurs-icon">
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar className="sariq" />
            <FaStar />
            <FaStar />
          </div>
        </div>
      </div>
    </div>
  );
}
