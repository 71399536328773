import react from "react";
import { FaAward, FaCoins, FaStar, FaWallet } from "react-icons/fa";
import "./Bank.css";

export default function Bank() {
  return (
    <div className="foydalanuvchi-bank-quti">
      <div className="hisob">
        <FaCoins className="icon" />
        <span>3 000 000</span>
      </div>
      <div className="chegirma">
        <FaWallet className="icon" />
        <span>16%</span>
      </div>
      <div className="daraja">
        <FaAward className="icon" />
        <span>
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar className="bosh" />
        </span>
      </div>
    </div>
  );
}
