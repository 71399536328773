import react from "react";
import "./Dasturlash.css";
import {
  FaBootstrap,
  FaCss3,
  FaDatabase,
  FaHtml5,
  FaJava,
  FaNode,
  FaNodeJs,
  FaPhp,
  FaPython,
  FaReact,
  FaShareSquare,
  FaSwift,
  FaVuejs,
} from "react-icons/fa";

export default function Dasturlash() {
  return (
    <div className="foydalanuvchi-daraja-quti">
      <span>
        Dasturlash tili darajasi{" "}
        <a href="#" title="Ulashish">
          <FaShareSquare className="daraja-yuborish" />
        </a>
      </span>
      <div className="foydalanuvchi-daraja-icon">
        <div className="front-end">
          <FaHtml5 className="icon-daraja" />
          <FaCss3 className="icon-daraja" />
          <FaNodeJs className="icon-daraja" />
          <FaBootstrap className="icon-daraja" />
          <FaReact className="icon-daraja" />
          <FaVuejs className="icon-daraja" />
        </div>
        <div className="back-end">
          <FaPhp className="icon-daraja" />
          <FaSwift className="icon-daraja" />
          <FaPython className="icon-daraja" />
          <FaNode className="icon-daraja" />
          <FaJava className="icon-daraja" />
          <FaDatabase className="icon-daraja" />
        </div>
      </div>
    </div>
  );
}
