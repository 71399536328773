import react from "react";
import { FaCoins } from "react-icons/fa";
import "./Mavzu.css";
import Royxat from "./Royxat";

function Mavzu({ kurslar, mavzu }) {
  let narx;
  if (kurslar.narx === 0) {
    narx = "Bepul";
  } else {
    narx = kurslar.narx;
  }
  return (
    <div className="qiymat-mavzu-scroll">
      <div className="qiymat-mavzu-quti">
        <div className="qiymat-quti-izoh">
          <div className="qiymat-quti-logo">
            <img src={kurslar.rasm} alt={kurslar.nomi} />
          </div>
          <div className="qiymat-quti-nomi">
            <h3>{kurslar.nomi} darslari</h3>
            <p>{kurslar.izoh}</p>
            <span>
              <FaCoins />
              &nbsp;{narx}
            </span>
          </div>
        </div>
        <Royxat mavzu={mavzu} />
      </div>
    </div>
  );
}

export default Mavzu;
