import react from "react";
import "./Mantiq.css";

import { FaShareSquare, FaStar } from "react-icons/fa";

export default function Mantiq() {
  return (
    <div className="foydalanuvchi-daraja-quti">
      <span>
        Mantiqiy masala darajasi{" "}
        <a href="#" title="Ulashish">
          <FaShareSquare className="daraja-yuborish" />
        </a>
      </span>
      <div className="foydalanuvchi-mantiq-icon">
        <FaStar className="sariq" />
        <FaStar className="sariq" />
        <FaStar className="sariq" />
        <FaStar className="sariq" />
        <FaStar className="sariq" />
        <FaStar className="sariq" />
        <FaStar className="sariq" />
        <FaStar className="sariq" />
        <FaStar />
        <FaStar />
      </div>
    </div>
  );
}
