import React from "react";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import { Controlled as ControlledEditor } from "react-codemirror2";
import { FaCheckCircle } from "react-icons/fa";

function Taxrirlovchi(qiymati) {
  const { til, nomi, qiymat, kodYozish, Natija, rasm } = qiymati;
  function kodYoz(taxrir, vaqt, qiymat) {
    kodYozish(qiymat);
  }
  return (
    <div className="tarxirlovchi-quti">
      <div className="tarxirlovchi-sarlavha">
        <img src={rasm} alt={til} />
        <span>{nomi}</span>
        <button
          onClick={() => Natija("", "", qiymat)}
          className="buyurtma-tugma"
        >
          <FaCheckCircle /> &nbsp;Bajarish
        </button>
      </div>
      <ControlledEditor
        onBeforeChange={kodYoz}
        value={qiymat}
        className="taxrirlovchi"
        options={{
          lineWrapping: true,
          lint: true,
          mode: til,
          size: 70,
          fontSize: 25,
          theme: "material",
          lineNumbers: true,
        }}
      />
    </div>
  );
}

export default Taxrirlovchi;
