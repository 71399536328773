import React from "react";
import "./Qiymat-sarlavha.css";

function QiymatSarlavha({ mavzuSoni }) {
  return (
    <div className="qiymat-sarlavha-quti">
      <div className="qiymat-sarlavha-mavzu">
        <p>Kurs haqida</p>
      </div>
      <div className="qidiruv-input">
        <div className="qiymat-sarlavha-soni">
          <p>Darslar soni: {mavzuSoni} ta</p>
        </div>
      </div>
    </div>
  );
}
export default QiymatSarlavha;
