import { React, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Menyu from "./Menyu";
import Qiymat from "./Qiymat";
import Foydalanuvchi from "./Foydalanuvchi";
import "./Loyiha.css";
import MBicon from "./Yuklash/icon";
import MBkurs from "./Yuklash/kurs.json";
import MBmavzu from "./Yuklash/mavzu.json";
import rasmKod from "./Rasm/js.png";

import Masala from "./Masala";
import Kurs from "./Kurs";
import Topilmadi from "./Topilmadi";
import Bosh from "./Bosh";

const iconlar = MBicon.map((qiymat) => {
  return qiymat;
});

const kurslar = MBkurs.map((qiymat) => {
  qiymat.rasm = require(`${qiymat.rasm}`).default;
  qiymat.video = require(`${qiymat.video}`).default;
  return qiymat;
});

const mavzu = MBmavzu.map((qiymat) => {
  return qiymat;
});

function Loyiha() {
  const [kurs_id, setKurs_id] = useState(6);
  const [kurs, setKurs] = useState("html");
  const [baza, setBaza] = useState("");
  const [html, setHtml] = useState("");
  const [css, setCss] = useState("");
  const [js, setJs] = useState("");

  const mavzuSoni =
    typeof mavzu[kurs_id] != "object" ? 0 : mavzu[kurs_id].length;
  function Natija(html = "", css = "", js = "") {
    setBaza(`
      <html> 
      <body>${html}</body>
      <style>${css}</style>
      <script>
       function chop(nom) {
         document.write(nom);
       } 
      </script>    
      <script>
      ${js}       
      </script>
      </html>
      `);
  }
  // useEffect(() => {
  //   const vaqt = setTimeout(() => {
  //     setBaza(`
  //      <html>
  //     <body>${html}</body>
  //     <style>${css}</style>
  //     <script>${function chop(nom) {
  //       document.write(nom);
  //     }} + ${js}</script>
  //     </html>
  //     `);
  //   }, 250);
  //   return () => clearInterval(vaqt);
  // }, [js]);
  return (
    <BrowserRouter>
      <div className="quti">
        <Menyu iconlar={iconlar} />
        <Routes>
          <Route exact path="/" element={<Bosh kurslar={kurslar} />} />
          <Route
            exact
            path="/web"
            element={
              <Qiymat
                kurslar={kurslar}
                mavzu={mavzu[kurs_id]}
                mavzuSoni={mavzuSoni}
                setKurs_id={setKurs_id}
                kurs_id={kurs_id}
              />
            }
          />
          <Route
            path="/mantiq"
            element={
              <Masala
                baza={baza}
                setHtml={setHtml}
                setCss={setCss}
                setJs={setJs}
                html={html}
                css={css}
                js={js}
                rasm={rasmKod}
                Natija={Natija}
              />
            }
          />
          <Route
            path={`/kurs/${kurs}`}
            element={
              <Kurs
                baza={baza}
                kurs={kurs}
                setHtml={setHtml}
                setCss={setCss}
                setJs={setJs}
                html={html}
                css={css}
                js={js}
                rasm={rasmKod}
                Natija={Natija}
              />
            }
          />
          <Route path="*" element={<Topilmadi />} />
        </Routes>
        <Foydalanuvchi />
      </div>
    </BrowserRouter>
  );
}

export default Loyiha;
