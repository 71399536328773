import React from "react";
import "./Savol.css";
let k = new Array();
function Tartib(son) {
  for (let i = 0; i < son; i++) {
    k[i] = i;
  }
  return k;
}

function Savol() {
  const faol = 1;
  return (
    <div className="masala-savol-quti">
      <div className="masala-savol-tartib">
        {Tartib(40).map((qiymat) => {
          return (
            <span className={faol === qiymat + 1 ? "faol" : ""}>
              {qiymat + 1}
            </span>
          );
        })}
      </div>
      <div className="masala-savol">
        Kvadratning tomoni <b>a</b> berilgan. Uning perimetri aniqlansin? <br />
        <b>Izoh: P = 4 * a</b>
      </div>
    </div>
  );
}

export default Savol;
