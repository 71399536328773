import React from "react";
import "./Masala.css";
import Taxrirlovchi from "../Taxrirlovchi";
import Sarlavha from "./Sarlavha";
import Savol from "./Savol";

function Masala({ js, setJs, baza, Natija, rasm }) {
  return (
    <div className="masala-quti">
      <Sarlavha
        chap={"Algoritm va mantiqiy masalalar"}
        ong={"Kiritish chiqarish operatori"}
      />
      <Savol />
      <Sarlavha chap={"Kod yozish oynasi"} ong={"Natija oynasi"} />
      <div className="masala-ijrochi-quti">
        <div className="masala-taxrirlovchi">
          <Taxrirlovchi
            til="javascript"
            nomi="JavaScript"
            qiymat={js}
            kodYozish={setJs}
            Natija={Natija}
            rasm={rasm}
          />
        </div>
        <div className="masala-korinish">
          <iframe
            className="natija"
            srcDoc={baza}
            title="output"
            sandbox="allow-scripts"
            frameBorder="0"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
}

export default Masala;
