import React from "react";
import IconMenyu from "./IconMenyu";

import Logo from "./Logo";
import "./Menyu.css";
function Menyu({ iconlar }) {
  return (
    <div className="menyu-quti">
      <Logo />
      <IconMenyu />
    </div>
  );
}

export default Menyu;
