import React from "react";

function Sarlavha({ chap = "", ong = "" }) {
  return (
    <div className="qiymat-sarlavha-quti">
      <div className="qiymat-sarlavha-mavzu">
        <p>{chap}</p>
      </div>
      <div className="qidiruv-input">
        <div className="qiymat-sarlavha-soni">
          <p>{ong}</p>
        </div>
      </div>
    </div>
  );
}
export default Sarlavha;
