import React from "react";
import VideoS from "../Video/html.mp4";

function Video(props) {
  return (
    <div>
      <video src={VideoS} controls width="450"></video>
    </div>
  );
}

export default Video;
