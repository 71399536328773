import "./Video.css";
import { FaShoppingCart } from "react-icons/fa";
function Video({ video }) {
  return (
    <div className="qiymat-quti-scroll">
      <div className="qiymat-mavzu-quti">
        <video src={video} controls />
        <button className="buyurtma-tugma">
          <FaShoppingCart className="icon-buyurtma" /> Buyurtma berish
        </button>
      </div>
    </div>
  );
}

export default Video;
