import React from "react";

function Royxat({ mavzu }) {
  if (typeof mavzu != "object") {
    mavzu = ["Mavjud emas"];
  }
  return (
    <div className="qiymat-quti-mavzu">
      <ol>
        {mavzu.map((qiymat) => {
          return <li>{qiymat}</li>;
        })}
      </ol>
    </div>
  );
}

export default Royxat;
