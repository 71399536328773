import React from "react";

function Dars(props) {
  return (
    <div>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus, voluptatem
      est unde eius suscipit sequi libero, molestias similique corrupti odit
      quibusdam sint dolorem nam tempore fugit itaque magni ducimus voluptas!
    </div>
  );
}

export default Dars;
