// let a = 7,
//   b = 8,
//   c;

import { faBorderStyle } from "@fortawesome/free-solid-svg-icons";

// if (a <= b) {
//   a = 0;
//   b = 0;
// } else {
//   c = a + b;
//   a = c;
//   b = c;
// }

// console.log(a, b);

// let tasischi = true,
//   yosh = 18,
//   mablag = 1000000,
//   shot = true,
//   litsenziya = true,
//   manzil = true;

// if (
//   ((tasischi == true) &
//     (yosh >= 18) &
//     (mablag >= 1000000) &
//     (shot == true) &
//     (manzil == true)) |
//   (litsenziya == true)
// ) {
//   console.log("Siz MCHJ ochishingiz mumkin");
// } else {
//   console.log("Mumkin emas");
// }

// let a = 7,
//   b = 10;

// if (a > 10 || b > 10) {
//   console.log(a, b);
// }

// let a = "5",
//   b = "5";

// if (a === b) {
//   console.log("Bu sonlar teng");
// } else {
//   console.log("teng emas");
// }

// let a = 7,
//   b = 15,
//   c = 12,
//   kichik,
//   katta;

// if (a + b > b + c && a + b > a + c) {
//   console.log(a, b);
// } else if (b + c > a + b && b + c > a + c) {
//   console.log(b, c);
// } else {
//   console.log(c, a);
// }

// if (a > b && b > c) {
//   katta = a;
//   kichik = c;
// } else if (c > b && b > a) {
//   katta = c;
//   kichik = a;
// } else if (b > a && a > c) {
//   katta = b;
//   kichik = c;
// } else if (c > a && a > b) {
//   katta = c;
//   kichik = b;
// }
// console.log(katta, kichik);

// let salom = "-Assalom-";

// console.log(salom.trim("-"));

const kun = 5;
// let k = 7;
// switch (kun) {
//   case 1:
//     console.log("Dushanba");
//     break;

//   case 2:
//     console.log("Seshanba");
//     break;

//   case 5:
//     console.log("Juma");
//     break;
//   default:
//     console.log("Bunday hafta kuni yo'q");
//     break;
// }

// const tomon = "s",
//   yonalish = 2;

// switch (tomon) {
//   case "s":
//     if (yonalish === 1) {
//       console.log("G'arb");
//     } else {
//       console.log("Sharq");
//     }

//   case "j":
//     if (yonalish === 1) {
//       console.log("Sharq");
//     } else {
//       console.log("G'arb");
//     }

//     break;
// }

// const a = 5;
// let sum;
// for (let i = 1; i <= 10; i++) {
//   sum = sum + i;
//   console.log(i);
// }
// console.log(sum);

// let a = 10;
// console.log(parseInt(a / 3));
// console.log(a % 3);

// const son = 121;
// let birSatr, onSatr, yuzSatr;

// let bir = son % 10,
//   on = parseInt(son / 10) % 10,
//   yuz = parseInt(son / 100);

// switch (bir) {
//   case 1:
//     birSatr = "bir";
//     break;

//   case 2:
//     birSatr = "ikki";

//   default:
//     break;
// }

// switch (on) {
//   case 1:
//     onSatr = "o'n";
//     break;
//   case 2:
//     onSatr = "yigirma";
//     break;
//   default:
//     break;
// }

// switch (yuz) {
//   case 1:
//     yuzSatr = "bir yuz";
//     break;

//   case 2:
//     yuzSatr = "ikki yuz";

//     break;
//   case 3:
//     yuzSatr = "uch yuz";
//     break;
//   default:
//     break;
// }

// console.log(yuzSatr, " ", onSatr, " ", birSatr);

// const n = 10;
// let S = 1;

// for (let i = 1; i <= n; i++) {
//   console.log(2 * i - 1);
// }

// (n + i) ** 2;

const n = 5;
let k = 1,
  s = 0;

for (let i = 1; i <= n; i++) {
  k = k * i;
  s = s + k;

  console.log(k, s);
}
