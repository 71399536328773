import react from "react";
import "./Malumot.css";
import rasm from "../Rasm/Rasuljon.jpg";
import { FaRegBell } from "react-icons/fa";

export default function Malumot() {
  return (
    <div className="foydalanuvchi-malumot">
      <div className="foydalanuvchi-rasm">
        <img src={rasm} />
      </div>
      <div className="foydalanuvchi-ism">
        <div className="foydalanuvchi-daraja">Mayor</div>
        <div className="foydalanuvchi-nomi">
          Yo'ldoshev Muazzamxon Tursunboy qizi
        </div>
      </div>
      <div className="foydalanuvchi-qongiroq">
        <FaRegBell className="icon-qongiroq" />
      </div>
    </div>
  );
}
