import React from "react";
import "./Foydalanuvchi.css";

import Malumot from "./Malumot";
import Dasturlash from "./Dasturlash";
import Mantiq from "./Mantiq";
import Kurs from "./Kurs";
import Bank from "./Bank";

function Foydalanuvchi() {
  return (
    <div className="foydalanuvchi-quti">
      <Malumot />
      <Dasturlash />
      <Mantiq />
      <Kurs />
      <Bank />
    </div>
  );
}

export default Foydalanuvchi;
