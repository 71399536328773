import React from "react";
import { FaHome } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "./Topilmadi.css";

function Topilmadi() {
  return (
    <div className="topilmadi-quti">
      <div className="topilmadi-404">404</div>
      <div className="topilmadi-sahifa">Uzr sahifa mavjud emas!!</div>
      <NavLink to="/" className="topilmadi-tugma-havola">
        <button className="topilmadi-tugma">
          <FaHome />
          &nbsp; Bosh sahifa
        </button>
      </NavLink>
    </div>
  );
}

export default Topilmadi;
