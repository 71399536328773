import React from "react";
import Kard from "./Kard";
import Qidiruv from "./Qidiruv";
import Yonalish from "./Yonalish";
import QiymatSarlavha from "./Qiymat-sarlavha";
import "./Qiymat.css";

function Qiymat({ kurslar, mavzu, mavzuSoni, kurs_id, setKurs_id }) {
  return (
    <div className="qiymat-quti">
      <Qidiruv />
      <Kard kurslar={kurslar} setKurs_id={setKurs_id} />
      <QiymatSarlavha mavzuSoni={mavzuSoni} />
      <Yonalish kurslar={kurslar[kurs_id]} mavzu={mavzu} />
    </div>
  );
}

export default Qiymat;
