import React from "react";
import "./Qiymat.css";
import Mavzu from "./Mavzu";
import Video from "./Video";

function Yonalish({ kurslar, mavzu, kurs_id }) {
  return (
    <div className="yonalish-quti">
      <Mavzu kurslar={kurslar} mavzu={mavzu} kurs_id={kurs_id} />
      <Video video={kurslar.video} />
    </div>
  );
}

export default Yonalish;
